import React, { useState, useEffect, useContext } from 'react';
// import { checkSignedIn } from '.../../ui-elements/cards/analytics/utils';
import {
  // NavItem,
  // NavLink,
  UncontrolledDropdown,
  // Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge,
  Button,
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import axios from 'axios';
import * as Icon from 'react-feather';
import { FormControlLabel, Switch } from '@material-ui/core';
// import classnames from 'classnames';
// import ReactCountryFlag from 'react-country-flag';
// import Autocomplete from '../../../components/@vuexy/autoComplete/AutoCompleteComponent';
// import { useAuth0 } from '../../../authServices/auth0/auth0Service';
import { history } from '../../../history';
// import { IntlContext } from '../../../utility/context/Internationalization';
import { UserContext } from '../../../hooks/UserContext';
import { SelectedClientContext } from '../../../hooks/SelectedClientContext';
// import { checkSignedIn } from '../../../views/ui-elements/cards/analytics/utils';
// import chartLogo from '../main/CraftingCard/assets/chart-48.png';
// import FrillWidget from './Widget';

import './navbarUser.scss';
import UserDropdown from './UserDropdown';
import { Link } from 'react-router-dom';
import LetterAvatar from '../../../components/custom/LetterAvatar';

const NavbarUser = (props) => {
  const { user, showDemo, setShowDemo, website } = useContext(UserContext);
  const { selectedClient } = useContext(SelectedClientContext);

  const [notifications, setNotifications] = useState([]);
  const [isFreeUser, setIsFreeUser] = useState(false);

  useEffect(() => {
    setIsFreeUser(website?.plan === 'Free' || website?.plan === 'Demo');
  }, [website]);

  useEffect(() => {
    axios.get('/api/notifications').then(
      (response) => setNotifications(response.data),
      (err) => {
        console.warn('Error fetching notifications');
        console.error(err);
      }
    );
  }, []);

  const dismissNotification = (id) =>
    axios
      .post('/api/notifications/dismiss', { id })
      .then(() =>
        setNotifications((prevState) => prevState.filter((v) => v.id !== id))
      );

  return (
    <ul
      className="nav navbar-nav navbar-nav-user float-right"
      style={{ flexWrap: 'nowrap' }}
    >
      {isFreeUser ? (
        <>
          {/* Don't show this toggle on small screens */}
          <FormControlLabel
            className="d-none d-lg-block align-self-center"
            control={
              <Switch
                color="primary"
                checked={showDemo}
                onChange={() => {
                  setShowDemo((d) => !d);
                }}
              />
            }
            label="Show demo data"
          />
          <div className="align-self-center">
            <Link to="/plans">
              <Button color="success shrink-btn">
                <Icon.Zap size={16} className="mr-1 d-none d-sm-inline" />
                Upgrade<span className="d-none d-md-inline"> Plan</span>
              </Button>
            </Link>
          </div>
        </>
      ) : null}
      <UncontrolledDropdown
        tag="li"
        className="dropdown-notification nav-item ml-md-1"
      >
        <DropdownToggle tag="a" className="nav-link nav-link-label">
          <Icon.Bell size={21} style={{ minWidth: '21px' }} />
          {notifications.length > 0 ? (
            <Badge pill color="primary" className="badge-up">
              {notifications.length}
            </Badge>
          ) : null}
        </DropdownToggle>
        <DropdownMenu tag="ul" right className="dropdown-menu-media">
          <li className="dropdown-menu-header">
            <div className="dropdown-header mt-0">
              <h3 className="text-white">{notifications.length} New</h3>
              <span className="notification-title">App Notifications</span>
            </div>
          </li>
          <PerfectScrollbar
            className="media-list overflow-hidden position-relative"
            options={{
              wheelPropagation: false,
            }}
          >
            {notifications.map((notif) => (
              <div
                className={`d-flex justify-content-between ${
                  notif.url ? 'clickable' : 'noclick'
                }`}
                key={notif.id}
                onClick={
                  notif.url
                    ? () => {
                        dismissNotification(notif.id);
                        history.push(notif.url);
                      }
                    : null
                }
              >
                <Media className="d-flex align-items-start">
                  <Media body>
                    <Media
                      heading
                      className="primary media-heading d-flex justify-content-between"
                      tag="h6"
                    >
                      <span>{notif.title}</span>
                      <small>
                        <time className="media-meta">
                          {
                            // One-liner to convert string to a proper date object,
                            // while allowing us to reuse the converted object without
                            // re-converting each time
                            // Then check to see if this notification happened today
                            new Date() -
                              (notif.date = new Date(notif.createdAt)) <
                            1000 * 60 * 60 * 24
                              ? notif.date.toLocaleTimeString('default', {
                                  hour: 'numeric',
                                  minute: '2-digit',
                                })
                              : notif.date.toLocaleDateString()
                          }
                        </time>
                        <span
                          className="noclick p-1"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Icon.X
                            className="feather-16 clickable"
                            onClick={() => dismissNotification(notif.id)}
                          />
                        </span>
                      </small>
                    </Media>
                    <p className="notification-text">{notif.text}</p>
                  </Media>
                </Media>
              </div>
            ))}
          </PerfectScrollbar>
          <li className="dropdown-menu-footer">
            <DropdownItem
              tag="a"
              className="p-1 text-center"
              onClick={() =>
                axios
                  .post('/api/notifications/dismiss/all')
                  .then(() => setNotifications([]))
              }
            >
              <span className="align-middle">Read all notifications</span>
            </DropdownItem>
          </li>
        </DropdownMenu>
      </UncontrolledDropdown>
      <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
        <DropdownToggle tag="a" className="nav-link dropdown-user-link p-1">
          <div className="user-nav d-sm-flex d-none">
            {!user.company ? (
              <>
                <span className="user-name text-bold-600 mt-1 ">
                  {props.userName}
                </span>
                <span className="user-status   ">
                  {selectedClient?.company
                    ? selectedClient.company
                    : user.company
                    ? user.company
                    : null}
                </span>
              </>
            ) : (
              <>
                <span className="user-name text-bold-600">
                  {props.userName}
                </span>
                <span className="company text-truncate">
                  {
                    // selectedClient.company
                    //   ? selectedClient.company
                    user.company ? user.company : null
                  }
                </span>
              </>
            )}
            {/* <span className="user-name text-bold-600">{props.userName}</span>
            <span className="user-status">
              {selectedClient.company
                ? selectedClient.company
                : user.company
                ? user.company
                : null}
            </span> */}
          </div>
          <span data-tour="user">
            <LetterAvatar
              char={
                user.company
                  ? user.company[0]
                  : user.first_name
                  ? user.first_name[0]
                  : user.email[0]
              }
            />
            {/* <div
              style={{
                backgroundColor: '#287fe6',
                borderRadius: '50%',
                width: '2.5rem',
                height: '2.5rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <span className="text-white" style={{ fontSize: '1.5rem' }}>
                {user.company
                  ? user.company[0]
                  : user.first_name
                  ? user.first_name[0]
                  : user.email[0]}
              </span>
            </div> */}
            {/* <img
                src={props.userImg}
                className="round"
                height="40"
                width="40"
                alt="avatar"
              /> */}
          </span>
        </DropdownToggle>
        <UserDropdown />
      </UncontrolledDropdown>
    </ul>
  );
};
export default NavbarUser;
