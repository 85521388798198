import React from 'react';

/**
 * @typedef {Object} LetterAvatarProps
 * @prop {string} char
 */
/**
 * @param {LetterAvatarProps} props
 */
function LetterAvatar(props) {
  return (
    <div
      style={{
        backgroundColor: '#287fe6',
        borderRadius: '50%',
        width: '2.5rem',
        height: '2.5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <span className="text-white" style={{ fontSize: '1.5rem' }}>
        {props.char[0]}
      </span>
    </div>
  );
}

export default LetterAvatar;
