import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { history } from '../../../history';
import { NavItem, NavLink } from 'reactstrap';
import * as Icon from 'react-feather';
import Select from 'react-select';
import { UserContext } from '../../../hooks/UserContext';
import { DateContext } from '../../../hooks/DateContext';
import { SelectedClientContext } from '../../../hooks/SelectedClientContext';
import {
  groupedMonthsFromStartDate,
  isCurrentMonth,
} from '../../../helpers/date-helpers';
import { clientNameDisplay } from '../../../helpers/general';

// import 'flatpickr/dist/themes/material_green.css';
const mql = window.matchMedia(`(min-width: 992px)`);

const NavbarBookmarks = (props) => {
  const { user, website } = useContext(UserContext);
  const { setSelectedDate, selectedDate } = useContext(DateContext);
  const { setClientList, selectedClient, setSelectedClient } = useContext(
    SelectedClientContext
  );

  // Options for select client dropdown list
  const [monthDropdownOptions, setMonthDropdownOptions] = useState(
    groupedMonthsFromStartDate(website?.createdAt)
  );

  // Track the screen width, for determining whether to show long or short
  // month names in the dropdown
  const [largeScreen, setLargeScreen] = useState(mql.matches);
  const [wideMonthSelect, setWideMonthSelect] = useState(true);
  const handleScreenSizeChange = () => setLargeScreen(mql.matches);
  useEffect(() => {
    mql.addEventListener('change', handleScreenSizeChange);
    return () => mql.removeEventListener('change', handleScreenSizeChange);
  });
  useEffect(
    () => setWideMonthSelect(largeScreen || user.role === 'Client'),
    [largeScreen, user.role]
  );

  // Get the client list
  useEffect(() => {
    if (['Editor', 'Admin'].includes(user.role))
      axios.get('/api/admin/clients').then(
        (response) => setClientList(response.data.clients),
        () => console.log('Failed to get client list')
      );
  }, []);

  // When a client is selected we should update the date dropdown to only include
  // months where we have data for that client
  useEffect(
    () =>
      setMonthDropdownOptions(
        groupedMonthsFromStartDate(
          selectedClient?.createdAt || website?.createdAt
        )
      ),
    [selectedClient, website]
  );

  const { sidebarVisibility } = props;

  // Not a fan of this being a local style like this
  // Not sure how it can be moved outside though
  // -- Style for month selector --
  const styles = {
    control: (base) => ({
      ...base,
      ...(!isCurrentMonth(selectedDate)
        ? {
            background: '#FFECD9',
            borderColor: '#FF9F43',
          }
        : {}),
    }),
    menu: (base) => ({
      ...base,
      ...(wideMonthSelect ? {} : { width: '120px' }),
    }),
  };

  return (
    <div className="mr-auto bookmark-wrapper d-flex align-items-center">
      <ul className="navbar-nav d-xl-none">
        <NavItem style={{ minWidth: '36px' }} className="mobile-menu mr-auto">
          <NavLink
            className="nav-menu-main menu-toggle hidden-xs is-active"
            onClick={sidebarVisibility}
          >
            <Icon.Menu className="ficon" />
          </NavLink>
        </NavItem>
      </ul>
      <ul
        className="navbar-nav bookmark-icons d-flex align-items-center"
        style={{
          gap: '0.33rem',
          paddingTop: '0.33rem',
          paddingBottom: '0.33rem',
        }}
      >
        <NavItem
          className="month-select"
          style={{
            width: wideMonthSelect ? '135px' : '85px',
            lineHeight: '1.2',
          }}
        >
          <Select
            //className="React w-100"
            styles={styles}
            classNamePrefix="select"
            value={{
              value: selectedDate,
              label: selectedDate.toLocaleString('default', {
                month: wideMonthSelect ? 'long' : 'short',
              }),
            }}
            name="month"
            options={monthDropdownOptions}
            onChange={(option) => setSelectedDate(option.value)}
          />
        </NavItem>
        {user.role === 'Client' ? (
          <NavItem>
            <span className="current-month">Current Loop Month</span>
          </NavItem>
        ) : (
          <NavItem
          //className="client-select"
          // style={{ width: '200px', lineHeight: 1.2 }}
          >
            <span className="ml-1">
              {selectedClient
                ? clientNameDisplay(selectedClient) +
                  ` (${selectedClient.plan})`
                : 'No client selected'}
            </span>
            <Icon.X
              className="clickable"
              size={15}
              onClick={() => {
                setSelectedClient();
                history.push('/');
              }}
              hidden={!selectedClient}
            />
          </NavItem>
        )}
      </ul>
    </div>
  );
};

export default NavbarBookmarks;
