export function clientNameDisplay(client) {
  return (
    client.company || client.website || client.uuid || client.id || '<Unknown>'
  );
}

/**
 * Use with useEffect to do the specified action on a given delay
 * @param {function()} callback
 */
export function inactivityEffect(callback, delay) {
  const timer = setTimeout(callback, delay);
  return () => clearTimeout(timer);
}
