// function is used in report section to get current and previous month
/**
 * Parses a date in YYYY-MM-DD format
 * @param {string} dateString String representing a date in YYYY-MM-DD format
 * @returns A date object corresponding to the given string
 * @deprecated
 *  Use selectedDate context item over dateRange context item. The proper date
 *  object is preferred over these formatted strings.
 */
export function parseDate(dateString) {
  let [y, m, d] = dateString.split('-');
  // Implicitly converts to int
  m -= 1;
  return new Date(y, m, d);
}

/**
 * @param {string} rangeStart
 * @param {string} rangeEnd
 * @returns
 *  Formatted strings representing the first days of the current and previous month
 * @deprecated Using proper date objects is preferred
 */
export function getMonthPair(rangeStart, rangeEnd) {
  const passedDate = parseDate(rangeStart);
  // Ignore rangeEnd
  const curDayOne = new Date(passedDate);
  curDayOne.setDate(1);
  // curDayOne.setMonth(curDayOne.getMonth() - 1);
  // const curLastDay = new Date(curDayOne);
  // curLastDay.setMonth(curLastDay.getMonth() + 1);
  // curLastDay.setDate(0);
  const prevLastDay = new Date(curDayOne);
  prevLastDay.setDate(0);
  const prevDayOne = new Date(prevLastDay);
  prevDayOne.setDate(1);
  const convertDate = (date) => {
    const year = date.getFullYear();
    // Months zero-indexed
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  return {
    currentMonth: convertDate(curDayOne),
    previousMonth: convertDate(prevDayOne),
  };
}

// function for checking current month is true or false
/**
 *
 * @param {Date|string} date
 */
export function isCurrentMonth(date) {
  if (typeof date === 'string') {
    date = parseDate(date);
  }

  const now = new Date();
  return (
    now.getMonth() === date.getMonth() &&
    now.getFullYear() === date.getFullYear()
  );
}

//////////////////////

/**
 * Creates a value object for a given month. As can be used in the options array
 * for the Select object
 * @param {Date} date
 *  The date for the month to generate. The secific day is ignored, only the month
 *  and year are used in generation.
 * @deprecated Uses old YYYY-MM-DD strings system. Proper date objects are preferred
 */
const dateRanger = (date) => {
  const ym = `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}`;
  const dayNum = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  const end = `${ym}-${dayNum.toString().padStart(2, '0')}`;
  const start = `${ym}-01`;

  return {
    value: [start, end],
    label: date.toLocaleString(undefined, { month: 'long' }),
  };
};

/**
 * Creates an array of items that can be used in react's Select object as options.
 * Months will be selectable, from the given start date to present. If the year
 * has rolled over since the given start date then months will be put into groups
 * with years as the labels. More recent months will be earlier in the list.
 * @param {string|Date} startDate
 */
function groupedMonthsFromStartDate(startDate) {
  // Make sure we have a proper date object
  startDate = new Date(startDate);
  const now = new Date();
  // For consistency, set the day and time component to 0 for both objects
  startDate.setDate(1);
  startDate.setHours(0, 0, 0, 0);
  now.setDate(1);
  now.setHours(0, 0, 0, 0);

  // Function to create the option object
  const dateOption = (d) => ({
    value: new Date(d),
    label: d.toLocaleString('default', { month: 'long' }),
  });

  // Two cases: The current year is the same as the start year or it's not
  const result = [];
  if (startDate.getFullYear() === now.getFullYear())
    // Loop over months from the start until now
    for (let cur = now; cur >= startDate; cur.setMonth(cur.getMonth() - 1))
      result.push(dateOption(cur));
  // We're into the following year
  else {
    let optGroup = {
      label: now.getFullYear(),
      options: [],
    };
    for (let cur = now; cur >= startDate; cur.setMonth(cur.getMonth() - 1)) {
      // First we should check if we're into the next year or not
      if (cur.getFullYear() < optGroup.label) {
        // Push the group to results
        result.push(optGroup);
        // Start a new year
        optGroup = {
          label: cur.getFullYear(),
          options: [],
        };
      }
      // Add the current month
      optGroup.options.push(dateOption(cur));
    }
    // Add the final partial group
    result.push(optGroup);
  }
  return result;
}
// changed from export module. export to export
export { dateRanger, groupedMonthsFromStartDate };
