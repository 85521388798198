const initialState = {
  todos: [],
  routeParam: null,
  filteredTodos: [],
  foo: 'bar',
};

const todo = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_TODOS':
      return { ...state, todos: action.todos, routeParam: action.routeParams };

    case 'UPDATE_TODOS':
      let todo = action.todo;
      let updatedState = { ...state.todos, [todo.id]: { ...todo } };
      return { ...state, todos: updatedState };

    case 'ACCEPT_TASK':
      state.todos.find((i) => i.id === action.id).isInProgress = !action.value;
      return { ...state };

    case 'COMPLETE_TASK':
      state.todos.find((i) => i.id === action.id).isCompleted = !action.value;
      return { ...state };

    case 'TASK_APPROVAL':
      let taskToApprove = state.todos.find((i) => i.id === action.id);
      taskToApprove.approval = action.value;
      return { ...state };
    // let taskToApprove = state.todos.find((i) => i.id === action.id);
    // // taskToApprove.approval = action.value;
    // taskToApprove.approval =
    //   taskToApprove.approval === action.value ? '' : action.value;
    // return { ...state };

    // case 'STAR_TASK':
    //   state.todos.find((i) => i.id === action.id).isStarred = !action.value;
    //   return { ...state };

    // case 'IMPORTANT_TASK':
    //   state.todos.find((i) => i.id === action.id).isImportant = !action.value;
    //   return { ...state };

    case 'TRASH_TASK':
      console.log('ARCHIVE TASK dispatch');
      let taskToTrash = state.todos.find((i) => i.id === action.id);
      taskToTrash.isTrashed = taskToTrash.isTrashed
        ? (taskToTrash.isTrashed = false)
        : (taskToTrash.isTrashed = true);
      // state.todos.find((i) => i.id === action.id).isTrashed = true;
      return { ...state };

    case 'CONFIRM_DELETE':
      state.todos.find((i) => i.id === action.id).confirmDelete = !action.value;
      return { ...state };

    case 'DELETE_TASK':
      let filteredTasks = state.todos.filter((item) => {
        return item.id !== action.id;
      });
      return { ...state, todos: filteredTasks };

    case 'UPDATE_EDITORS_LABEL':
      let taskToUpdateUsers = state.todos.find((i) => i.id === action.id).users;
      const taskToUpdateNames = taskToUpdateUsers.map(
        (user) => `${user.first_name} ${user.last_name}`
      );
      const labelName = `${action.label.first_name} ${action.label.last_name}`;
      const currentNameIndex = taskToUpdateNames.indexOf(labelName);

      if (!taskToUpdateNames.includes(labelName))
        taskToUpdateUsers.push(action.label);
      else taskToUpdateUsers.splice(currentNameIndex, 1);
      return { ...state };
    //   let taskToUpdate = state.todos.find(i => i.id === action.id).tags
    //   if (!taskToUpdate.includes(action.label)) taskToUpdate.push(action.label)
    //   else taskToUpdate.splice(taskToUpdate.indexOf(action.label), 1)
    //   return { ...state }

    case 'UPDATE_PRIORITY_LABEL':
      let taskToUpdate = state.todos.find((i) => i.id === action.id);
      taskToUpdate.priority = action.label;
      return { ...state };

    case 'UPDATE_CATEGORY':
      let taskToUpdateCategory = state.todos.find((i) => i.id === action.id);
      taskToUpdateCategory.category = action.category;
      return { ...state };

    case 'UPDATE_TASK':
      let todoToUpdate = state.todos.find((i) => i.id === action.id);
      console.log(todoToUpdate);
      todoToUpdate.title = action.title;
      todoToUpdate.desc = action.desc;
      return { ...state };

    case 'ADD_TASK':
      return { ...state }; //line 96

    case 'SEARCH_TASK':
      if (action.val.length) {
        let filteredTodos = state.todos.filter((query) => {
          return (
            query.title.toLowerCase().includes(action.val) ||
            query.desc.toLowerCase().includes(action.val) ||
            (query.username &&
              query.username.toLowerCase().includes(action.val))
          );
        });
        return { ...state, filteredTodos };
      } else {
        return { ...state };
      }

    case 'CHANGE_FILTER':
      state.routeParam = action.filter;
      return { ...state };

    default:
      return state;
  }
};

export default todo;
